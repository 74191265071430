var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative inline-block h-9 group",style:({ width: `${_vm.width}rem` })},[_c('div',{staticClass:"absolute flex items-center w-full h-full bg-gray-200 rounded-full cursor-pointer track-animation",style:(_vm.cssTranslateValue)},[_c('div',{class:[
				'box-border w-1/2 flex duration-300 bg-white border border-gray-300 border-solid rounded-full handle-animation h-full',
				_vm.buttonClasses
			],style:({ 'margin-left': _vm.buttonMargin })}),_vm._v(" "),_c('div',{staticClass:"absolute flex text-sm font-bold leading-tight text-center space-between",style:({ width: `${_vm.width}rem` }),on:{"click":_vm.handleSelectionUpdated}},_vm._l((_vm.options),function(option){return _c('span',{key:option.value,class:[
					'w-1/2 duration-300',
					_vm.selectedOption.value === option.value ? 'text-black' : 'text-gray-500'
				]},[_vm._v("\n\t\t\t\t"+_vm._s(option.text)+"\n\t\t\t")])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }