<template>
	<div class="flex flex-col justify-center h-24 px-3 text-sm text-left border-t border-gray-300 min-h-24 md:h-auto md:border-none whitespace-nowrap">
		<h1 class="mb-1 font-bold uppercase truncate md:mb-2 min-h-6">
			{{ mapHeading }}
		</h1>
		<div
			ref="filter-bar"
			:class="[
				isMobile && !isSearchOpen ? 'overflow-x-auto': 'overflow-x-hidden',
				'overflow-y-hidden md:w-full md:flex md:flex-wrap -mx-1 min-h-13 hide-horizontal-scrollbar'
			]"
		>
			<WwLabeledSwitch
				v-if="isMobile"
				class="mx-1 -mb-3.5"
				:options="[{ text: 'List', value: LIST }, { text: 'Map', value: MAP }]"
				@selection-changed="updateMapListSelection"
			/>
			<span
				v-for="(filter, index) in combinedQuickFilters"
				:key="filter[index]"
				class="inline-block py-1 mx-1"
			>
				<button
					type="button"
					:class="[
						'items-center px-3.5 py-1 rounded-full bg-white font-bold',
						active('button', filter.id) ? 'border-green-500 border-2' : 'border m-px border-gray-300'
					]"
					@click="handleMenuClick(filter.id)"
				>
					{{ filterSet.allFilters[filter.id].name }}
				</button>
			</span>

			<span class="flex-wrap inline-block md:flex">
				<MapControlsDropdown
					:is-mobile="isMobile"
					:dropdown-buttons="dropdownButtonsArray"
					:active-filters="activeFilters"
					:reset-active-filters="reset"
					parent-ref-name="filter-bar"
					@menu-clicked="handleMenuClick"
				/>
			</span>
			<span
				v-show="activeFilters.length > 0"
				class="py-1 mx-1"
			>
				<button
					type="button"
					class="inline-block items-center px-3.5 py-1 rounded-full bg-white font-bold border m-px border-gray-300"
					@click="resetFilters()"
				>
					Reset
				</button>
			</span>
		</div>
	</div>
</template>

<script async>
import { mapGetters, mapMutations, mapState } from 'vuex'

import WwLabeledSwitch from '@/components/multiUse/WwLabeledSwitch.vue'
import { LIST, MAP } from '@/constants/map/map-modes.js'
import { logError } from '@/utils/error-handling.js'

export default {
	components: {
		WwLabeledSwitch,
		MapControlsDropdown: () => import('@/components/map/MapControlsDropdown.vue')
	},
	props: {
		switchPosition: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			activeFilters: [],
			reset: false,
			isMounted: false,
			LIST,
			MAP
		}
	},
	computed: {
		...mapState([
			'meta'
		]),
		...mapGetters([ 'isMobile' ]),
		...mapGetters('map', [ 'searchMode' ]),
		...mapGetters('search', [ 'isSearchOpen' ]),
		mapHeading() {
			return this.meta?.h1
		},
		filterSet() {
			return this.$store.state.city.filterSet || {}
		},
		combinedQuickFilters() {
			const tempFilters = []
			for (const index in this.filterSet.quickFilters) {
				const filterGroup = this.filterSet.quickFilters[index]
				for (let j = 0; j < filterGroup.filters.length; j++) {
					const filter = filterGroup.filters[j]
					tempFilters.push(filter)
				}
			}
			return tempFilters
		},
		dropdownButtonsArray() {
			const dropdowns = this.filterSet.dropdowns
			let newGroup = {}
			const tempArray = []
			const tempGroup = this.removeEmptyFilters(dropdowns, {})

			for (const group in tempGroup) {
				newGroup = Object.assign({}, {
					id: group, name: dropdowns[group].name, filters: dropdowns[group].filters
				})
				tempArray.push(newGroup)
			}

			return tempArray
		},
		baseUrl: function () {
			return `${this.$store.state.location.url}/`
		},
		urlFilters: function () {
			return this.$route.fullPath.substring(1).split(/[+/]/) || []
		}
	},
	watch: {
		'filterSet.allFilters'() {
			if (this.filterSet.allFilters !== undefined && this.isMounted) {
				this.setFiltersOnLoad()
				this.isMounted = false
			}
		}
	},
	mounted() {
		this.isMounted = true
	},
	methods: {
		...mapMutations('map', [ 'setSearchMode' ]),
		async setFiltersOnLoad() {
			if (this.urlFilters.length && this.filterSet) {
				for (let i = 0; i < this.urlFilters.length; i++) {
					const filter = this.urlFilters[i]
					const hasFilter = await this.filterSet.allFilters[filter]
					if (hasFilter) {
						this.activeFilters.push(filter)
					}
				}
			}
		},
		setActiveProperty(filter) {
			for (let i = 0; i < this.activeFilters.length; i++) {
				if (this.activeFilters.includes(filter)) {
					const tempGroup = this.findFilterGroup(filter)

					if (tempGroup === 'dropdown') {
						const group = { ...this.findFilter(filter) }
						if (this.filterSet.dropdowns[group.groupId]) {
							this.filterSet.dropdowns[group.groupId].filters[group.filterIndex].active = true
							return
						}
					}

					if (tempGroup === 'quickFilters') {
						for (let i = 0; i < this.filterSet.quickFilters.length; i++) {
							const groupIndex = i
							const filterArray = this.filterSet.quickFilters[groupIndex].filters
							const filterIndex = this.findWithAttr(filterArray, 'id', filter)

							if (filterIndex > -1) {
								this.filterSet.quickFilters[groupIndex].filters[filterIndex].active = true
								return
							}
						}
					}
				}
			}
		},
		findWithAttr(array, attr, value) {
			for (let i = 0; i < array.length; i += 1) {
				if (array[i][attr] === value) {
					return i
				}
			}
			return -1
		},
		findFilter(value) {
			for (const groupId in this.filterSet.dropdowns) {
				const group = this.filterSet.dropdowns[groupId]
				if (Object.keys(group).length > 0) {
					const filterIndex = this.findWithAttr(group.filters, 'id', value)
					if (filterIndex > -1) {
						return { groupId, filterIndex }
					}
				}
			}
		},
		findFilterGroup(value) {
			for (const groupId in this.filterSet.dropdowns) {
				const ddGroup = this.filterSet.dropdowns[groupId]

				if (Object.keys(ddGroup).length > 0) {
					const filterIndex = this.findWithAttr(ddGroup.filters, 'id', value)
					if (filterIndex > -1) {
						return 'dropdown'
					}
				}
			}

			for (let i = 0; i < this.filterSet.quickFilters.length; i++) {
				const groupIndex = i
				const filterArray = this.filterSet.quickFilters[groupIndex].filters
				const filterIndex = this.findWithAttr(filterArray, 'id', value)

				if (filterIndex > -1) {
					return 'quickFilters'
				}
			}
		},
		deactivateCheckbox(filterId) {
			const group = { ...this.findFilter(filterId, 'object') }
			if (this.filterSet.dropdowns[group.groupId]) {
				this.filterSet.dropdowns[group.groupId].filters[group.filterIndex].active = false
			}
		},
		deactivateAllCheckboxes() {
			for (const group in this.filterSet.dropdowns) {
				for (const filterIndex in this.filterSet.dropdowns[group].filters) {
					this.filterSet.dropdowns[group].filters[filterIndex].active = false
				}
			}
		},
		deactivateButton(filterId) {
			for (let i = 0; i < this.filterSet.quickFilters.length; i++) {
				const groupIndex = i
				const filterArray = this.filterSet.quickFilters[groupIndex].filters
				const filterIndex = this.findWithAttr(filterArray, 'id', filterId)
				if (filterIndex > -1) {
					this.filterSet.quickFilters[groupIndex].filters[filterIndex].active = false
					return
				}
			}
		},
		deactivateAllButtons() {
			for (let i = 0; i < this.filterSet.quickFilters.length; i++) {
				const groupIndex = i
				const filterArray = this.filterSet.quickFilters[groupIndex].filters
				for (const filterIndex in filterArray) {
					this.filterSet.quickFilters[groupIndex].filters[filterIndex].active = false
				}
			}
		},
		removeEmptyFilters(dropdowns, tempGroup) {
			for (const group in dropdowns) {
				const dd = dropdowns[group]
				if (dd?.filters?.length) {
					tempGroup = Object.assign({}, tempGroup, { [dd.id]: dd.filters })
				}
			}
			return tempGroup
		},
		updateMapListSelection(value) {
			this.setSearchMode(value)
		},
		resetFilters() {
			this.activeFilters = []
			this.reset = true
			this.deactivateAllCheckboxes()
			this.deactivateAllButtons()
			this.applyFilters()
		},
		setActiveFilters(value) {
			const index = this.activeFilters.indexOf(value)
			if (this.activeFilters.includes(value)) {
				this.activeFilters.splice(index, 1)
				this.deactivateCheckbox(value)
				this.deactivateButton(value)
			} else {
				this.activeFilters.push(value)
				this.setActiveProperty(value)
			}
			this.applyFilters()
		},
		handleMenuClick(value) {
			this.reset = false
			this.setActiveFilters(value)
		},
		active(type, value) {
			switch (type) {
				case 'button':
					return this.activeFilters.includes(value)
				default:
					return false
			}
		},
		applyFilters: function () {
			const tempArray = []
			let filterString = ''
			try {
				for (const index in this.filterSet.quickFilters) {
					const activeSet = this.filterSet.quickFilters[index].filters.filter(filterData => filterData.active).map(activeData => activeData.value || activeData.id)
					if (activeSet.length < 1) continue
					// NOTE this is ugly, but they need to be ordered in this arbitrary way for seo
					if (index == 1) { // features
						tempArray[2] = activeSet.join('+')
					} else if (index == 0) { // features2
						tempArray[1] = activeSet.join('+')
					} else {
						const e = new Error('filterset group has index not accounted for')
						logError(e)
					}
				}

				for (const groupId in this.filterSet.dropdowns) {
					if (Object.keys(this.filterSet.dropdowns[groupId]).length > 0) {
						const activeDropdownSet = this.filterSet.dropdowns[groupId].filters.filter(filterData => filterData.active).map(activeData => activeData.value || activeData.id)
						if (activeDropdownSet.length < 1) continue
						// NOTE this is ugly, but they need to be ordered in this arbitrary way for seo
						switch (groupId) {
							case 'amenities':
								tempArray[3] = activeDropdownSet.join('+')
								break
							case 'type':
								tempArray[0] = activeDropdownSet.join('+')
								break
							case 'sortBy':
								tempArray[4] = activeDropdownSet.join('+')
								break
							default:
								break
						}
					}
				}

				if (tempArray.length > 0) {
					filterString = tempArray.filter(Boolean).join('/') // filter Boolean will filter out blank items
				}
				if (filterString === '') {
					filterString = 'businesses'
				}
				const newUrl = this.baseUrl + filterString
				if (newUrl !== this.$route.fullPath) {
					this.$router.push(newUrl)
				}
			} catch (e) {
				logError(e)
			}
		}
	}
}
</script>
