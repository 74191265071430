<template>
	<div
		class="relative inline-block h-9 group"
		:style="{ width: `${width}rem` }"
	>
		<div
			class="absolute flex items-center w-full h-full bg-gray-200 rounded-full cursor-pointer track-animation"
			:style="cssTranslateValue"
		>
			<div
				:class="[
					'box-border w-1/2 flex duration-300 bg-white border border-gray-300 border-solid rounded-full handle-animation h-full',
					buttonClasses
				]"
				:style="{ 'margin-left': buttonMargin }"
			/>
			<div
				class="absolute flex text-sm font-bold leading-tight text-center space-between"
				:style="{ width: `${width}rem` }"
				@click="handleSelectionUpdated"
			>
				<span
					v-for="option in options"
					:key="option.value"
					:class="[
						'w-1/2 duration-300',
						selectedOption.value === option.value ? 'text-black' : 'text-gray-500'
					]"
				>
					{{ option.text }}
				</span>
			</div>
		</div>
	</div>
</template>

<script async>
export default {
	name: 'ToggleButton',
	props: {
		options: {
			type: Array,
			required: true,
			validator(options) {
				return (Array.isArray(options) && options.length === 2)
			}
		},
		initialOption: {
			type: Object,
			default: null
		},
		width: {
			type: Number,
			default: 8
		},
		buttonClasses: {
			type: String,
			default: ''
		}
	},
	emits: [ 'selection-changed' ],
	data() {
		return {
			selectedOption: {}
		}
	},
	computed: {
		cssTranslateValue() {
			return {
				'--transform-translateX': this.translateValue
			}
		},
		buttonMargin() {
			return this.selectedOption.value === this.options[0].value ? `-${this.width / 2}rem` : '0rem'
		},
		translateValue() {
			return `translateX(${this.width / 2}rem)`
		}
	},
	async mounted() {
		await this.$nextTick()
		if (this.initialOption) {
			this.setSelectedOption()
		} else {
			this.selectedOption = this.options[0]
			this.$emit('selection-changed', this.selectedOption.value)
		}
	},
	methods: {
		handleSelectionUpdated() {
			this.selectedOption = this.selectedOption.value === this.options[0].value
				? this.options[1]
				: this.options[0]
			this.$emit('selection-changed', this.selectedOption.value)
		},
		setSelectedOption() {
			this.selectedOption = this.initialOption
			this.$emit('selection-changed', this.selectedOption.value)
		}
	}
}
</script>

<style scoped lang="scss">
	.track-animation > .handle-animation {
		transform: var(--transform-translateX);
	}
</style>
