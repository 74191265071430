var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col justify-center h-24 px-3 text-sm text-left border-t border-gray-300 min-h-24 md:h-auto md:border-none whitespace-nowrap"},[_c('h1',{staticClass:"mb-1 font-bold uppercase truncate md:mb-2 min-h-6"},[_vm._v("\n\t\t"+_vm._s(_vm.mapHeading)+"\n\t")]),_vm._v(" "),_c('div',{ref:"filter-bar",class:[
			_vm.isMobile && !_vm.isSearchOpen ? 'overflow-x-auto': 'overflow-x-hidden',
			'overflow-y-hidden md:w-full md:flex md:flex-wrap -mx-1 min-h-13 hide-horizontal-scrollbar'
		]},[(_vm.isMobile)?_c('WwLabeledSwitch',{staticClass:"mx-1 -mb-3.5",attrs:{"options":[{ text: 'List', value: _vm.LIST }, { text: 'Map', value: _vm.MAP }]},on:{"selection-changed":_vm.updateMapListSelection}}):_vm._e(),_vm._v(" "),_vm._l((_vm.combinedQuickFilters),function(filter,index){return _c('span',{key:filter[index],staticClass:"inline-block py-1 mx-1"},[_c('button',{class:[
					'items-center px-3.5 py-1 rounded-full bg-white font-bold',
					_vm.active('button', filter.id) ? 'border-green-500 border-2' : 'border m-px border-gray-300'
				],attrs:{"type":"button"},on:{"click":function($event){return _vm.handleMenuClick(filter.id)}}},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.filterSet.allFilters[filter.id].name)+"\n\t\t\t")])])}),_vm._v(" "),_c('span',{staticClass:"flex-wrap inline-block md:flex"},[_c('MapControlsDropdown',{attrs:{"is-mobile":_vm.isMobile,"dropdown-buttons":_vm.dropdownButtonsArray,"active-filters":_vm.activeFilters,"reset-active-filters":_vm.reset,"parent-ref-name":"filter-bar"},on:{"menu-clicked":_vm.handleMenuClick}})],1),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeFilters.length > 0),expression:"activeFilters.length > 0"}],staticClass:"py-1 mx-1"},[_c('button',{staticClass:"inline-block items-center px-3.5 py-1 rounded-full bg-white font-bold border m-px border-gray-300",attrs:{"type":"button"},on:{"click":function($event){return _vm.resetFilters()}}},[_vm._v("\n\t\t\t\tReset\n\t\t\t")])])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }